.leaderboard {
  max-width: 800px;
  .player-position {
    background: $white;
    border: 5px solid $yellow;
    box-shadow: 0px 0px 20px #ffe292;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      aspect-ratio: 1;
      margin-bottom: 0;
    }
    .confetti {
      position: fixed;
      pointer-events: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1000;
    }
  }

  .all-positions {
    height: 100%;
    display: grid;
    row-gap: 10px;
    @include media-breakpoint-up(sm) {
      row-gap: 20px;
    }

    .position {
      //display: grid;
      //row-gap: 50px;
    }
  }
  .notoptin {
    background-color: rgba(rgb(227, 176, 172), 0.9);
  }
}

.top3 {
  background-color: $white;
  position: absolute;
  max-width: 350px;
  height: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  padding-bottom: 30px;
  svg {
    display: block;
    width: 100%;
  }

  h1 {
    margin-top: -60px;
  }
}
