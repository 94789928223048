@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Book.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Semibold.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Bold.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@import "./variables.scss";
@import "./global.scss";
@import "views/Frontpage/Frontpage.scss";
@import "views/Game/Game.scss";
@import "views/Instructions/Instructions.scss";
@import "views/Leaderboard/Leaderboard.scss";
@import "views/Nagrade/Nagrade.scss";
@import "views/Optin/Optin.scss";
