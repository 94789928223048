body {
  //background: url(/assets/images/bg_desktop.jpg) no-repeat center center;
  background-color: $pink;
  background-size: 100% 100%;
  min-height: 800px;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  @include media-breakpoint-down(sm) {
    background: $pink;
    background-size: 100% 100%;
    background-attachment: fixed;
    height: 100vh;
    min-height: auto;
  }

  #app {
    margin: 0 auto;
    //max-width: 1200px;
    height: 100%;
  }
}

.logo-game {
  position: absolute;
  //left: 10px;
  top: 10px;
  @include media-breakpoint-down(sm) {
    position: relative;
    width: 80px;
  }
}

h1 {
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
}

a {
  text-decoration: none;
  color: $yellow;
  &:hover {
    color: $yellow;
    background-clip: none;
  }
}

.text-golden {
  background: url(/assets/images/texture.jpg) no-repeat center center;
  background-size: cover;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    color: $white !important;
  }

  &:active {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.btn-gold {
  background-image: url("/assets/images/texture.jpg");
  background-color: $white;
  background-size: 105%;
  background-position: center center;
  transition: all 0.5s ease;
  //letter-spacing: 0.2em;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.5),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &:active {
    border-color: transparent !important;
  }
}

.text-yellow {
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.lead {
  font-weight: 600;
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.soundicon {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 0;

  path {
    fill: white;
  }
  @include media-breakpoint-down(sm) {
    width: 30px;
    height: 30px;
  }
}

.buttons {
  width: 300px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.h-stars {
  background-image: url(/assets/images/stars-nagrade.svg);
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 60px;
}

.button-link {
  outline: none;
  border: 0;

  font-weight: 600;
  background-color: transparent;
}
