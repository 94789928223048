.optin {
  max-width: 600px;
  .form-floating {
    color: $black;
  }

  .form-check {
    input,
    label {
      cursor: pointer;
    }
  }

  .errorMsg {
    color: $yellow;
  }
}
