.instructions {
  max-width: 600px;

  .usp {
    img {
      filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));

      transform: translatey(0px);
      animation: float 6s ease-in-out infinite;
      animation-delay: 1s;

      @include media-breakpoint-down(sm) {
        height: 50px;
      }
    }
  }

  @keyframes float {
    0% {
      filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
      transform: translatey(0px);
    }
    50% {
      filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
      transform: translatey(-10px);
    }
    100% {
      filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
      transform: translatey(0px);
    }
  }
}
