.game {
  .buns-container {
    @include media-breakpoint-up(sm) {
      height: calc(100vh - 80px);
      display: flex;
    }
  }

  .spotit-bun {
    position: relative;
    background: #ffffff;
    border: 4px solid #efdf80;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 10px;
    width: 350px;
    height: 350px;
    border-radius: 100%;
    @include media-breakpoint-down(sm) {
      width: 300px;
      height: 300px;
    }
    //overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      background-image: url(/assets/images/bun_decoration.svg);
    }

    &__decorleft {
      &:before {
        width: 89px;
        height: 86px;
        left: -20px;
        top: -20px;
      }

      @include media-breakpoint-down(sm) {
        width: 240px;
        height: 240px;
        &:before {
          background-size: 100%;
          width: 60px;
          height: 60px;
          left: -10px;
          top: -10px;
        }
      }
    }
    &__decorright {
      &:before {
        width: 89px;
        height: 86px;
        right: -20px;
        top: -20px;
        transform: rotate(90deg);
      }
    }

    .bun-item {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      height: 30%;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &__1 {
        top: 20%;
        left: 10%;
      }
      &__2 {
        top: 3%;
        right: 33%;
      }
      &__3 {
        top: 35%;
        left: 37%;
      }
      &__4 {
        left: 10%;
        bottom: 15%;
      }
      &__5 {
        right: 22%;
        bottom: 6%;
      }
      &__6 {
        right: 4%;
        bottom: 40%;
      }

      img {
        cursor: pointer;
        //max-width: 50px;
      }
    }
  }

  .status {
    font-size: 1.3rem;
    font-weight: 900;
    letter-spacing: 0.05em;
    color: $red;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    > div {
      width: 120px;
      background: rgba(255, 255, 255, 0.8);
      padding: 5px 20px;
      @include media-breakpoint-down(sm) {
        width: 100px;
        padding: 5px 10px;
        border-radius: 0 !important;
      }
    }

    .clicked {
      width: 175px;
      @include media-breakpoint-down(sm) {
        width: 60%;
      }
    }

    .match {
      width: 80px;
      color: #30854f;
    }

    .time {
      width: 125px;
      @include media-breakpoint-down(sm) {
        width: 40%;
      }
    }
  }
}

.score-booster {
  position: absolute;
  //opacity: 1;
  z-index: 2;
  width: 100%;
  height: 100%;
  line-height: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  border-radius: 100%;
  color: white;
  font-weight: 600;
  font-size: 5rem;
  animation: scaleAnimation 1s ease-in-out;
  animation-iteration-count: 1;
  overflow: hidden;

  span,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    font-weight: 900;
  }

  img {
    height: 60%;
  }

  &.win {
    background: $gradient-golden;
  }
  &.lose {
    background: $red;
  }
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@for $i from 1 through 31 {
  .spotit-bun__decorright {
    img#item_#{$i} {
      //min-width: 80%;
      width: random_range(100%, 100%);
      transform: rotate(random_range(-25deg, 25deg));
    }
  }
}

@for $i from 1 through 31 {
  .spotit-bun__decorleft {
    img#item_#{$i} {
      //min-width: 80%;
      width: random_range(100%, 100%);
      transform: rotate(random_range(-25deg, 25deg));
    }
  }
}

img#item_4,
img#item_21 {
  padding: 10px !important;
}

/* img#item_13,
img#item_2,
img#item_7,
img#item_5,
img#item_15,
img#item_26,
img#item_14,
img#item_19,
img#item_20,
img#item_10,
img#item_30 {
  max-width: 80% !important;
} */
.record-wrapper {
  border-radius: 10px;
  border: 2px solid $yellow;
  display: inline-block;
  padding: 10px 50px;
  background-image: url(/assets/images/stars2.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 10px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
  span {
    @include media-breakpoint-up(sm) {
      //display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.countdown {
  //background-color: #fff;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  h1 {
    font-size: 200px;
    font-weight: 900;
    color: white;
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    //transform: scale(0) rotate(-0.5turn);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    //transform: scale(0) rotate(-0.5turn);
  }
}
