.frontpage {
  .bun {
    img {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .button-container {
    max-width: 400px;
    margin: 0 auto;
  }
}
